@import url(https://fonts.googleapis.com/css?family=Exo+2&display=swap);
body {
	word-break: break-word;
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
		'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

iframe {
	pointer-events: none;
}

.hideOnMobile {
	display: none !important;
}

@media (max-width: 400px) {
	.rdrMonth {
		width: 22em;
	}

	.MuiGrid-root .rccs,
	.MuiGrid-root .rccs .rccs__card {
		width: 100% !important;
	}
}

.MuiTableCell-head {
	font-size: 12px !important;
	font-weight: 700 !important;
}

.MuiTableCell-root {
	padding: 8px 20px 8px 16px !important;
}

