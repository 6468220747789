@import url('https://fonts.googleapis.com/css?family=Exo+2&display=swap');

.MuiTableCell-head {
	font-size: 12px !important;
	font-weight: 700 !important;
}

.MuiTableCell-root {
	padding: 8px 20px 8px 16px !important;
}
